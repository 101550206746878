import React, { useState } from 'react';
import { IoClose } from 'react-icons/io5'; // Import the close icon
import './Contantsection2.css'; // External CSS for styling

const Contantsection2 = () => {
  const [showPopup, setShowPopup] = useState(false);

  const handleBuyNowClick = () => {
    setShowPopup(true); // Show the popup
  };

  const handleClosePopup = () => {
    setShowPopup(false); // Hide the popup
  };

  const handleFormSubmit = (e) => {
    e.preventDefault(); // Prevent page reload
    console.log("Form submitted");
    setShowPopup(false); // Close the popup after submission
  };

  return (
    <div className="container-contant-section-2">
      <div className="Sectiontwo-contant-section-2">
        <div className="Sectiontwo-left-section">
          <h1 className="Sectiontwo-heading">Get Your Banana <br /> Powder Now!</h1>
          <p className="Sectiontwo-paragraph">Boost your nutrition effortlessly—buy Healthy Organic Banana Powder today and feel the difference!</p>
          <button className="Sectiontwo-buy-now-btn" onClick={handleBuyNowClick}>
            Buy Now <span className="Sectiontwo-arrow-icon">→</span>
          </button>
        </div>
        <div className="Sectiontwo-right-section">
          <img src="/banana.png" alt="Basket of Organic Vegetables" className="Sectiontwo-image" />
        </div>
      </div>

      {/* Popup Container */}
      {showPopup && (
        <div className="popup-overlay">
          <div className="popup-container">
            <div className="popup-header">
              <h2>Contact Form</h2>
              <IoClose className="close-icon" onClick={handleClosePopup} />
            </div>
            <form onSubmit={handleFormSubmit}>
              <input type="text" placeholder="Full Name" required />
              <input type="email" placeholder="Email" required />
              <textarea placeholder="Message" required></textarea>
              <button type="submit">Send Message</button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Contantsection2;
